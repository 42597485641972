import { useModelVariables } from "@/hooks/useModelVariables";
import { useTimeHorizons } from "@/hooks/useTimeHorizons";
import { ModelVariable, TimeHorizonShape } from "@/models";
import { ModalContainer } from "@/modules/shared";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
} from "@headlessui/react";
import { DatabaseIcon, XIcon } from "lucide-react";

import React, { useState } from "react";

type Props = {
    modelVariableId: string;
    timeHorizonId: string;
    onChange: ({
        modelVariableId,
        timeHorizonId,
    }: {
        modelVariableId: string;
        timeHorizonId?: string;
    }) => void;
};

export default function ModelQuantity({
    modelVariableId,
    timeHorizonId,
    onChange,
}: Props) {
    const { numericalModelVariables, isLoading: isLoadingModelVariables } =
        useModelVariables();
    const { timeHorizons, isLoading: isLoadingTimeHorizons } =
        useTimeHorizons();

    const [query, setQuery] = useState("");

    const [modelVariable, setModelVariable] = useState<ModelVariable | null>(
        numericalModelVariables?.find(
            (variable) => variable.id === modelVariableId,
        ) || null,
    );

    const [timeHorizon, setTimeHorizon] = useState<TimeHorizonShape | null>(
        timeHorizons?.find((horizon) => horizon.id === timeHorizonId) || null,
    );

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button
                className={`flex items-center justify-center rounded-sm px-2 py-2 text-center capitalize
                hover:border-white/20 hover:bg-sapien-blue hover:text-white focus:outline-none
                focus:ring-opacity-50 ${
                    !!modelVariableId
                        ? "border-gray-300/50 bg-sapien-blue text-white"
                        : "border-gray-300/30 bg-slate-100 text-slate-900"
                }`}
                onClick={() => setIsOpen(true)}
            >
                <DatabaseIcon className="h-4 w-4" />
            </button>
            <ModalContainer
                isModalOpen={isOpen}
                setIsModalOpen={() => setIsOpen(false)}
                size="s"
            >
                <div className="flex justify-between">
                    <h3 className="w-3/4 text-lg font-bold">
                        Replace text with a quantity from the model
                    </h3>
                </div>

                <div className="flex flex-col gap-4 overflow-visible p-4">
                    <div className="relative flex flex-col gap-2">
                        <Combobox
                            onChange={(value) => {
                                const modelVariable =
                                    numericalModelVariables.find(
                                        (variable) => variable.id === value,
                                    );
                                setModelVariable(modelVariable);
                                if (!modelVariable?.uses_time) {
                                    setTimeHorizon(null);
                                }
                            }}
                        >
                            <label>Model Variable</label>
                            <ComboboxInput
                                className="w-full rounded-md border-gray-300 bg-white p-2 shadow"
                                displayValue={(value) => modelVariable?.label}
                                onChange={(e) => {
                                    setQuery(e.target.value);
                                }}
                                placeholder={`${modelVariable?.label || "Search for a model variable"}`}
                            />
                            <ComboboxOptions
                                className="absolute z-100 mt-20 flex max-h-96 w-full flex-col space-y-2 overflow-y-auto
                                    rounded-md border-gray-300 bg-white p-2 shadow transition-all duration-300
                                    scrollbar scrollbar-thumb-gray-500 scrollbar-thumb-rounded-full scrollbar-w-2
                                    focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm"
                            >
                                {numericalModelVariables
                                    .filter((variable) =>
                                        variable.label
                                            .toLowerCase()
                                            .includes(query.toLowerCase()),
                                    )
                                    .map((variable) => (
                                        <ComboboxOption
                                            key={variable.id}
                                            value={variable.id}
                                            className="rounded-md p-2 text-gray-900 hover:bg-sapien-blue hover:text-white"
                                        >
                                            {variable.label}
                                        </ComboboxOption>
                                    ))}
                            </ComboboxOptions>
                        </Combobox>
                    </div>
                    {modelVariable?.uses_time && (
                        <div className="flex flex-col gap-2">
                            <select
                                className="w-full rounded-md border-gray-300 bg-white p-2 shadow"
                                onChange={(e) =>
                                    setTimeHorizon(
                                        timeHorizons.find(
                                            (horizon) =>
                                                horizon.id === e.target.value,
                                        ) || null,
                                    )
                                }
                            >
                                <option value="">Select Time Horizon</option>
                                {timeHorizons.map((horizon) => (
                                    <option key={horizon.id} value={horizon.id}>
                                        Time Horizon{horizon.time_index}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    <div className="flex justify-end gap-2">
                        <button
                            disabled={
                                !modelVariable ||
                                (modelVariable.uses_time && !timeHorizon)
                            }
                            onClick={() => {
                                onChange({
                                    modelVariableId: modelVariable?.id,
                                    timeHorizonId: timeHorizon?.id,
                                });
                            }}
                            className="rounded-md border-gray-300 bg-sapien-blue p-2 text-white shadow"
                        >
                            Save
                        </button>
                        <button
                            onClick={() => setIsOpen(false)}
                            className="rounded-md border-gray-300 bg-red-500 p-2 text-white shadow"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
}
