import Color from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Indent } from "./Indent";
import { LineHeight } from "./LineHeight";
import { TextStyleExtended } from "./TextEditor";
import { TextType } from "./TypographyType";
import PlaceHolder from "@tiptap/extension-placeholder";
import { RichText } from "@/models";
import { useCallback } from "react";
import { CustomStyle } from "./ColorClasses";
import { DataAttributes } from "./DataAttributes";

let timerId: ReturnType<typeof setTimeout>;
function debounce<T extends (...args: any[]) => void>(
    func: T,
    delay: number,
): (...args: Parameters<T>) => void {
    return function (this: any, ...args: Parameters<T>) {
        // Specify 'this' type as 'any'
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func.apply(this, args); // 'this' context preserved
        }, delay);
    };
}

export function useDebouncedEditor({
    richText,
    isEditable = true,
    updateOverride,
    editedId,
    waitMilliseconds = 250,
    placeholder = "Add some text...",
    onBlur,
}: {
    richText: RichText | App.Data.RichTextData;
    isEditable: boolean;
    updateOverride?: (json: RichText | App.Data.RichTextData) => void;
    editedId?: string | null;
    waitMilliseconds?: number;
    placeholder?: string;
    onBlur?: (_: RichText | App.Data.RichTextData) => any | void;
}) {
    const debouncedUpdate = useCallback(
        debounce(function (rich_text: RichText | App.Data.RichTextData) {
            if ("function" === typeof updateOverride) {
                updateOverride(rich_text);
            }
        }, waitMilliseconds),
        [waitMilliseconds, editedId],
    );

    const editor = useEditor(
        {
            editable: isEditable,
            extensions: [
                StarterKit,
                TextType,
                Color,
                LineHeight,
                Underline,
                TextStyleExtended,
                FontFamily,
                Indent,
                TextAlign.configure({
                    types: ["heading", "paragraph", "typography"],
                }),
                PlaceHolder.configure({
                    placeholder,
                }),
                CustomStyle,
                DataAttributes,
            ],
            content: richText as RichText,
            onUpdate({ editor }) {
                debouncedUpdate(editor.getJSON() as RichText);
            },
            onBlur({ editor }) {
                if ("function" === typeof onBlur) {
                    onBlur(editor.getJSON() as RichText);
                }
            },
        },
        [editedId, isEditable, waitMilliseconds, updateOverride],
    );

    return editor;
}
