import { sapienAxios } from "@/inertia-utils/hooks";
import { useSelectedSimulation } from "./useSimulationStore";
import { useQuery } from "@tanstack/react-query";
import { ModelVariable, ModelVariableDataType } from "@/models";

export function useModelVariables() {
    const { selectedSimulation } = useSelectedSimulation();

    const {
        data,
        isLoading,
    } = useQuery({
        queryKey: ["model-variables", selectedSimulation?.id],
        queryFn: async () => {
            const { data } = await sapienAxios.get<ModelVariable[]>(
                "model-builder.model-variables.index",
                {
                    simulationId: selectedSimulation?.id,
                },
            );
            return data;
        },
        enabled: !!selectedSimulation?.id,
        select: (data) => ({
            modelVariables: data,
            numericalModelVariables: data.filter(
                (variable) =>
                    variable.data_type === ModelVariableDataType.Number,
            ),
        }),
    });

    return {
        modelVariables: data?.modelVariables ?? [],
        numericalModelVariables: data?.numericalModelVariables ?? [],
        isLoading,
    };
}
