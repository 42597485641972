import { useQuery } from "@tanstack/react-query";
import { useSelectedSimulation } from "./useSimulationStore";
import { sapienAxios } from "@/inertia-utils/hooks";
import { TimeHorizonShape } from "@/models";

export function useTimeHorizons() {
    const {selectedSimulation} = useSelectedSimulation();

    const {data: timeHorizons, isLoading} = useQuery({
        queryKey: ['time-horizons', selectedSimulation?.id],
        queryFn: async () => {
            const {data} = await sapienAxios.get<TimeHorizonShape[]>('creator.design.time-horizons.index',{
                simulationId: selectedSimulation?.id,
            });
            return data;
        },
        enabled: !!selectedSimulation?.id,
        initialData: [],
    });

    return {
        timeHorizons,
        isLoading,
    };
}
