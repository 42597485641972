import { Extension } from '@tiptap/core'
import { Mark } from '@tiptap/core'

export interface DataAttributeOptions {
  // List of allowed data attributes
  allowedAttributes: string[]
  // Optional default attributes
  defaultAttributes?: Record<string, string>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    dataAttributes: {
      /**
       * Set data attributes
       */
      setDataAttributes: (attributes: Record<string, string>) => ReturnType
      /**
       * Remove data attributes
       */
      removeDataAttributes: () => ReturnType
      /**
       * Toggle a single data attribute
       */
      toggleDataAttribute: (name: string, value: string) => ReturnType
    }
  }
}

export const DataAttributes = Mark.create<DataAttributeOptions>({
  name: 'dataAttributes',

  addOptions() {
    return {
      allowedAttributes: [
        'model-variable-id',
        'time-horizon-id',
      ],
      defaultAttributes: {},
    }
  },

  addAttributes() {
    return {
      ...this.options.allowedAttributes.reduce((attrs, name) => ({
        ...attrs,
        [name]: {
          default: this.options.defaultAttributes?.[name] || null,
          parseHTML: element => element.dataset[name],
          renderHTML: attributes => {
            if (!attributes[name]) return {}
            return {
              [`data-${name}`]: attributes[name],
            }
          },
        },
      }), {}),
    }
  },

  parseHTML() {
    return [
      {
        tag: '*[data-model-variable-id], *[data-time-horizon-id]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes, 0]
  },

  addCommands() {
    return {
      setDataAttributes:
        (attributes: Record<string, string>) =>
        ({ chain }) => {
          // Filter out non-allowed attributes
          const filteredAttributes = Object.entries(attributes).reduce(
            (acc, [key, value]) => {
              if (this.options.allowedAttributes.includes(key)) {
                acc[key] = value
              }
              return acc
            },
            {} as Record<string, string>
          )

          return chain()
            .setMark(this.name, filteredAttributes)
            .run()
        },

      removeDataAttributes:
        () =>
        ({ chain }) => {
          return chain()
            .unsetMark(this.name)
            .run()
        },

      toggleDataAttribute:
        (name: string, value: string) =>
        ({ chain, editor }) => {
          if (!this.options.allowedAttributes.includes(name)) {
            return false
          }

          const attributes = editor.getAttributes(this.name)
          const newAttributes = { ...attributes }

          if (newAttributes[name] === value) {
            delete newAttributes[name]
          } else {
            newAttributes[name] = value
          }

          return chain()
            .setMark(this.name, newAttributes)
            .run()
        },
    }
  },
})